<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <legend class="bv-no-focus-ring col-form-label pt-0">
                    الصورة الشخصية
                  </legend>
                  <div v-if="file || userInfo.photo" class="image-after-upload">
                    <feather-icon
                      icon="XCircleIcon"
                      size="28"
                      class="image-after-upload-remove"
                      @click="
                        file = null;
                        userInfo.photo = '';
                      "
                    />
                    <img
                      :src="
                        userInfo.photo
                          ? userInfo.photo.full_path + userInfo.photo.file_name
                          : file
                      "
                      class="img-fluid"
                    />
                  </div>
                  <b-form-file v-else class="form-group-file" @change="uploadImage" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="اسم المستخدم" label-for="vi-name" class="required">
                  <b-form-input
                    id="vi-name"
                    v-model="userInfo.name"
                    placeholder="ادخل الاسم المستخدم"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="الإيميل" label-for="vi-email" class="required">
                  <b-form-input
                    id="vi-email"
                    v-model="userInfo.email"
                    placeholder="ادخل الإيميل "
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="رقم الهاتف" label-for="vi-phone" class="required">
                  <b-form-input
                    id="vi-phone"
                    v-model="userInfo.phone"
                    placeholder="ادخل رقم الهاتف"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <button
                  class="btn change-password"
                  @click="showResetPasswordModal = !showResetPasswordModal"
                >
                  تغيير كلمة المرور
                </button>
              </b-col>
              <b-col md="12" class="mt-2 d-flex justify-content-between">
                <button onclick="history.back()" class="filter-main-btn">
                  إلـــــــــغــــــــاء
                </button>
                <button
                  :disabled="
                    userInfo.name == '' || userInfo.email == '' || userInfo.phone == ''
                  "
                  class="add-main-btn"
                  @click="updateProfileFun"
                >
                  حـــــفــــظ التغييرات
                </button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      title="تغيير كلمة المرور"
      v-model="showResetPasswordModal"
      hide-footer
      centered
    >
      <div class="profile">
        <div class="header-content p-0" style="min-height: auto">
          <div class="header-content-form p-0">
            <b-row class="align-items-center w-100 m-0">
              <b-col lg="12" class="px-0">
                <b-form-group
                  class="header-content-form-group mt-0 position-relative required"
                >
                  <label>كلمة المرور القديمة</label>
                  <b-form-input
                    placeholder="*********"
                    :type="typeOldPassword"
                    v-model="userInfo.old_password"
                    dir="rtl"
                  ></b-form-input>
                  <span
                    @click="
                      typeOldPassword == 'password'
                        ? (typeOldPassword = 'text')
                        : 'password' || typeOldPassword == 'text'
                        ? (typeOldPassword = 'password')
                        : 'text'
                    "
                  >
                    <eye-icon
                      class="show-password"
                      :class="typeOldPassword == 'text' ? 'show-password-active' : ''"
                  /></span>
                </b-form-group>
              </b-col>
              <b-col lg="12" class="px-0">
                <b-form-group
                  class="header-content-form-group position-relative required"
                >
                  <label>كلمة المرور الجديدة</label>
                  <b-form-input
                    placeholder="*********"
                    v-model="userInfo.password"
                    :type="typePassword"
                    dir="rtl"
                  ></b-form-input>
                  <span
                    @click="
                      typePassword == 'password'
                        ? (typePassword = 'text')
                        : 'password' || typePassword == 'text'
                        ? (typePassword = 'password')
                        : 'text'
                    "
                  >
                    <eye-icon
                      class="show-password"
                      :class="typePassword == 'text' ? 'show-password-active' : ''"
                  /></span>
                </b-form-group>
              </b-col>
              <b-col lg="12" class="px-0">
                <b-form-group
                  class="header-content-form-group position-relative required"
                >
                  <label>تأكيد كلمة المرور الجديدة</label>
                  <b-form-input
                    placeholder="*********"
                    v-model="userInfo.password_confirmation"
                    :type="typeConfigPassword"
                    dir="rtl"
                  ></b-form-input>
                  <span
                    @click="
                      typeConfigPassword == 'password'
                        ? (typeConfigPassword = 'text')
                        : 'password' || typeConfigPassword == 'text'
                        ? (typeConfigPassword = 'password')
                        : 'text'
                    "
                  >
                    <eye-icon
                      class="show-password"
                      :class="typeConfigPassword == 'text' ? 'show-password-active' : ''"
                  /></span>
                </b-form-group>
              </b-col>
              <b-col
                lg="12"
                class="my-1 px-0 d-lg-flex d-sm-contents justify-content-between"
              >
                <b-button class="filter-main-btn" @click="showResetPasswordModal = false">
                  إلـــــــغــــــاء
                </b-button>
                <b-button
                  :disabled="
                    userInfo.old_password == null ||
                    userInfo.password == null ||
                    userInfo.password_confirmation == null
                  "
                  class="add-main-btn"
                  @click="changePasswordFun"
                >
                  تــــغــيـــيـر
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormFile,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormFile,
  },
  data() {
    return {
      file: null,
      showResetPasswordModal: false,
      typePassword: "password",
      typeConfigPassword: "password",
      typeOldPassword: "password",
      userInfo: {
        id: "",
        name: "",
        phone: "",
        photo: "",
        email: "",
        old_password: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getProfileInfo: "getProfileInfo",
    }),
  },
  watch: {
    getProfileInfo(data) {
      if (data) {
        this.userInfo = data;
      }
    },
  },
  created() {},
  methods: {
    ...mapActions({
      loadProfileInfo: "loadProfileInfo",
      updateProfile: "updateProfile",
      uploadFiles: "uploadFiles",
      changePassword: "changePassword",
    }),
    uploadImg(file) {
      const formData = new FormData();
      formData.append("file", file);
      this.uploadFiles(formData).then((response) => {
        this.userInfo.photo = response.content;
      });
    },
    uploadImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.file = e.target.result;
        };
        // this.file = input.files[0];
        reader.readAsDataURL(input.files[0]);
        this.uploadImg(event.target.files[0]);
      }
    },
    updateProfileFun() {
      this.updateProfile({
        name: this.userInfo.name,
        email: this.userInfo.email,
        phone: this.userInfo.phone,
        photo:
          this.userInfo.photo && this.userInfo.photo.file_name
            ? this.userInfo.photo.file_name
            : null,
      });
    },
    changePasswordFun() {
      this.changePassword({
        old_password: this.userInfo.old_password,
        password: this.userInfo.password,
        password_confirmation: this.userInfo.password_confirmation,
      }).then((res) => {
        this.showResetPasswordModal = false;
      });
    },
  },
  mounted() {
    this.loadProfileInfo();
  },
};
</script>
